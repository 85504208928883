@import '~vuetify/src/stylus/main'

a
  cursor: pointer
  text-decoration: none

.invalid-feedback
  color: $red.accent-2
  margin-top: -20px

.card
  .snack__content
    max-width: 100vw
    min-width: 100vw

.nopadding
 padding:0
.debugb
 border: 1px solid red 
.application--light .toolbar
    background-color: #fefefe
